import React from 'react';

function Contact() {
  return (
    <section className="p-5 m-5 bg-white rounded shadow">
      <h2 className="text-2xl font-bold">Contact</h2>
      <p>Email: <a className="text-blue-500 hover:underline" href="mailto:ubitechino@gmail.com">ubitechino@gmail.com</a></p>
    </section>
  );
}

export default Contact;