import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Typewriter from 'typewriter-effect';
import "./App.css";
import About from "./subpages/About/About";
import Projects from "./subpages/Projects/Projects";
import Contact from "./subpages/Contacts/Contact";
import Home from "./subpages/home/home";
import Products from "./subpages/Store/Products/products"

function App() {
  const [indicatorPosition, setIndicatorPosition] = useState({
    left: 0,
    width: 0,
  });
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [servicesDropdownVisible, setServicesDropdownVisible] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const handleMouseEnter = (event) => {
    const { offsetLeft, clientWidth } = event.currentTarget;
    setIndicatorPosition({ left: offsetLeft, width: clientWidth });
  };

  const handleDropdownToggle = () => {
    clearTimeout(timeoutId);
    setDropdownVisible(true);
  };

  const handleServicesDropdownToggle = () => {
    clearTimeout(timeoutId);
    setServicesDropdownVisible(true);
  };

  const handleDropdownHide = () => {
    const id = setTimeout(() => {
      setDropdownVisible(false);
      setServicesDropdownVisible(false);
    }, 300);
    setTimeoutId(id);
  };

  const handleDropdownMouseEnter = () => {
    clearTimeout(timeoutId);
    setDropdownVisible(true);
  };

  const handleServicesDropdownMouseEnter = () => {
    clearTimeout(timeoutId);
    setServicesDropdownVisible(true);
  };

  return (
    
    <Router>
      <div className="App bg-gray-100 text-gray-800 flex flex-col min-h-screen">
        <header className="fixed top-0 left-0 right-0 bg-emerald-900 text-white p-4 z-10">
          <h2 className="text-xl">Ubitech Innovations</h2>
          <nav className="relative">
            <ul className="flex justify-center space-x-4">
              <li onMouseEnter={handleMouseEnter}>
                <Link className="text-lg font-bold font-open-sans hover:underline" to="/">
                  Home
                </Link>
              </li>
              <li onMouseEnter={handleMouseEnter}>
                <Link className="text-lg font-bold font-open-sans hover:underline" to="/about">
                  About
                </Link>
              </li>
              <li onMouseEnter={handleMouseEnter}>
                <Link className="text-lg font-bold font-open-sans hover:underline" to="/projects">
                  Projects
                </Link>
              </li>
              <li
                onMouseEnter={handleServicesDropdownToggle}
                onMouseLeave={handleDropdownHide}
                className="relative"
              >
                <Link className="text-lg font-bold font-open-sans hover:underline" to="#">
                  Services
                </Link>
                <ul
                  onMouseEnter={handleServicesDropdownMouseEnter}
                  onMouseLeave={handleDropdownHide}
                  className={`absolute bg-white text-gray-800 mt-2 rounded shadow-lg transition-opacity duration-300 ease-in-out ${
                    servicesDropdownVisible
                      ? "opacity-100"
                      : "opacity-0 pointer-events-none"
                  }`}
                  style={{ transitionProperty: "opacity", width: "200px" }} // Custom width
                >
                  <li className="p-2 hover:bg-gray-200">
                    <Link to="/services/service1">Service 1</Link>
                  </li>
                  <li className="p-2 hover:bg-gray-200">
                    <Link to="/services/service2">Service 2</Link>
                  </li>
                  <li className="p-2 hover:bg-gray-200">
                    <Link to="/services/service3">Service 3</Link>
                  </li>
                </ul>
              </li>
              <li
                onMouseEnter={handleDropdownToggle}
                onMouseLeave={handleDropdownHide}
                className="relative"
              >
                <Link className="text-lg font-bold font-open-sans hover:underline" to="#">
                  Store
                </Link>
                <ul
                  onMouseEnter={handleDropdownMouseEnter}
                  onMouseLeave={handleDropdownHide}
                  className={`absolute bg-white text-gray-800 mt-2 rounded shadow-lg transition-opacity duration-300 ease-in-out ${
                    dropdownVisible
                      ? "opacity-100"
                      : "opacity-0 pointer-events-none"
                  }`}
                  style={{ transitionProperty: "opacity", width: "200px" }} // Custom width
                >
                  <li className="p-2 hover:bg-gray-200">
                    <Link to="/store/type1">Secondhand Shop</Link>
                  </li>
                  <li className="p-2 hover:bg-gray-200">
                    <Link to="/store/products">Products</Link>
                  </li>
                  <li className="p-2 hover:bg-gray-200">
                    <Link to="/store/type3">something🤔</Link>
                  </li>
                </ul>
              </li>
              <li onMouseEnter={handleMouseEnter}>
                <Link className="text-lg font-bold font-open-sans hover:underline" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
            <div
              className="absolute h-1 rounded-full bg-gradient-to-r from-lime-700 to-teal-800 transition-all duration-300"
              style={{
                left: `${indicatorPosition.left}px`,
                width: `${indicatorPosition.width}px`,
                bottom: "-4px", // Adjust this value to position the indicator below the text
              }}
            />
          </nav>
        </header>

        <main className="pt-20 flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/contact" element={<Contact />} />
            {/* Add routes for store types here */}
            <Route path="/store/type1" element={<div>Store Type 1</div>} />
            <Route path="/store/products" element={<Products />} />
            <Route path="/store/type3" element={<div>Store Type 3</div>} />
            {/* Add routes for services here */}
            <Route path="/services/service1" element={<div>Service 1</div>} />
            <Route path="/services/service2" element={<div>Service 2</div>} />
            <Route path="/services/service3" element={<div>Service 3</div>} />
          </Routes>
        </main>

        <footer className="bg-emerald-800 text-white p-16 text-center">
          <p>&copy; {new Date().getFullYear()} Ubitech Innovations</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;