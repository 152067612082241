import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://api.ubitechino.com/api/projects');
        setProducts(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error fetching products: {error.message}</div>;

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
      {products.map(product => (
        <div key={product.id} className="border rounded-lg shadow-lg p-4">
          <img src={product.imageUrl} alt={product.name} className="w-full h-48 object-cover rounded-t-lg" />
          <h2 className="text-xl font-bold mt-2">{product.name}</h2>
          <p className="text-gray-700">{product.description}</p>
          <p className="text-sm text-gray-500">Created At: {new Date(product.createdAt).toLocaleDateString()}</p>
        </div>
      ))}
    </div>
  );
};

export default ProductList;