import React, { useEffect, useState } from 'react';

const sections = [
  {
    title: 'About us',
    content: `Here at Ubitech, it is our most humble wish that you will find something worth learning and exploring. With over thirty years of experience in solving technical problems and multiple patents under our belt, we pride ourselves on being at the forefront of innovation and creativity.`,
  },
  {
    title: 'What we offer',
    content: `At Ubitech, our services range from basic robotic kits for beginners to advanced custom solutions for seasoned engineers. Whether you are a hobbyist, educator, or professional, we have the tools and expertise to support your projects. Our team is dedicated to making technology accessible and understandable, and we offer comprehensive tutorials and support to guide you every step of the way.`,
  },
  {
    title: 'Our Expertise',
    content: `Man there is a LOT of stuff to put in this section. I'll do it later :)`,
  },
  {
    title: 'Custom Solutions',
    content: `We understand that every project is unique, and sometimes off-the-shelf products just won't cut it. That's why we offer custom solutions tailored to your specific needs. Whether you need a custom-designed robot, specialized software, or unique hardware components, we have the skills and resources to bring your vision to life. If we don't have what you're looking for, we'll help you create it!`,
  },
  {
    title: 'Educational Resources',
    content: `We believe that learning and exploration are at the heart of innovation. That's why we offer a wide range of educational resources to help you expand your knowledge and skills. From detailed tutorials and guides to hands-on workshops, we provide the tools you need to become proficient in robotics and technology.`,
  },
  {
    title: 'Community and Support',
    content: `At Ubitech, you're not just a customer; you're part of a community. We foster a collaborative environment where like-minded individuals can share ideas, ask questions, and work together on exciting projects. Our support team is always available to assist you with any technical issues or questions you may have, ensuring that you have the help you need to succeed.`,
  },
  {
    title: 'A little about me...',
    content: `I have always had a passion for learning and making things. There have been many moments in my career where I enjoyed the opportunity to share this spark with those around me. This website is about that. It is about exploring that initial rush when the thing you have toiled over makes its first move.
    
    I have enjoyed a path that has taken me down many roads. Having worked in aerospace, medical research, robotics, and controls and automation, I have had the opportunity to develop a lot of domain knowledge in each of these areas. My track record of patents in these fields shows that I have ideas and concepts that progressed each of these fields. Covid changed the path for me, and now I want to explore how the knowledge and experience I have gained can ignite these fires for you or perhaps the children in your world.  
    
    The age we embrace, with the tools and resources at our fingertips, has never been equaled. There are so many possibilities that are within reach of everyone. I look forward to exploring them with you!
    
    Henry`,
  },
];

function About() {
  const [visibleSections, setVisibleSections] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisibleSections((prev) => [...prev, prev.length]);
    }, 200); // Change the delay as needed

    return () => clearTimeout(timer);
  }, [visibleSections]);

  return (
    <>
      {sections.map((section, index) => (
        <section
          key={index}
          className={`p-5 m-5 bg-white rounded shadow transition-opacity duration-500 ${
            visibleSections.includes(index) ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <h2 className="text-2xl font-bold">{section.title}</h2>
          <p>{section.content}</p>
        </section>
      ))}
    </>
  );
}

export default About;