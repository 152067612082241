import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Home = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.ubitechino.com/api/home');
                setData(response.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Welcome</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {data.map(item => (
                    !item.hidden && ( // Check if hidden is false
                        <a 
                            key={item.id} 
                            href={item.imageUrl} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="bg-white rounded-lg shadow p-4 transition-transform duration-300 transform hover:scale-105"
                        >
                            <img 
                                src={item.imageUrl} 
                                alt={item.title} 
                                className="w-full h-48 object-cover rounded-t-lg"
                            />
                            <h2 className="text-xl font-semibold mt-2">{item.title}</h2>
                            <p className="text-gray-600">{item.description}</p>
                            <p className="text-gray-400 text-sm">Created At: {new Date(item.createdAt).toLocaleString()}</p>
                            <p className="text-gray-400 text-sm">Updated At: {new Date(item.updatedAt).toLocaleString()}</p>
                        </a>
                    )
                ))}
            </div>
        </div>
    );
};

export default Home;